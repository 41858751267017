import React, { useState } from "react";
import { Fab, TextareaAutosize } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Link } from "react-router-dom";
import QrScan from "react-qr-reader";
import { useToolProvider } from "../contexts/tool";

const QRscanner = () => {
  const [qrscan, setQrscan] = useState("no result");
  const { state, updateInventory } = useToolProvider();

  const handleError = (err) => {
    console.error(err);
  };
  const [scanResults, setScanResults] = useState();
  const [scanTrigger, setScanTrigger] = useState(false);
  const qrScanTrigger = () => {
    setScanTrigger(true);
  };
  const handleScan = (data) => {
    if (data) {
      setScanResults(data);
    }
  };
  const submit = () => {
    updateInventory(scanResults);
  };

  return (
    <div>
      <Link to="/">
        <Fab style={{ marginRight: 10 }} color="primary">
          <ArrowBack />
        </Fab>
      </Link>
      <span>QR Scanner</span>

      <center>
        <div style={{ marginTop: 30 }}>
          <QrScan
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ height: 240, width: 320 }}
            reactivateTimeout={500}
            onEvent={qrScanTrigger}
          />
        </div>
      </center>

      <TextareaAutosize
        style={{ fontSize: 18, width: 320, height: 100, marginTop: 100 }}
        rowsMax={4}
        defaultValue={qrscan}
        value={qrscan}
      />
      <div>
        <h3>{scanResults}</h3>

        {scanTrigger && <h1>it worked</h1>}
        <button onClick={submit}>Submit</button>
        <p>{JSON.stringify(state)}</p>
      </div>
    </div>
  );
};

export default QRscanner;
