import { useToolProvider } from "../contexts/tool";

export const Inventory = () => {
  const { state, setState } = useToolProvider();
  const save = () => {
    localStorage.setItem("tools", JSON.stringify(state));
  };
  const load = () => {
    const saved = localStorage.getItem("tools");
    setState(JSON.parse(saved));
  };

  return (
    <div>
      <h1>Inventory</h1>
      <p>{JSON.stringify(state)}</p>
      <button onClick={save}>Save</button>
      <button onClick={load}>Load</button>
      <table>
        <thead>
          <td>Name</td>
          <td>Is In Stock</td>
        </thead>
        <tbody>
          {state.inventory.map((item) => {
            return (
              <tr>
                <td>{item.name}</td>
                <td>{item.inStock.toString()}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
