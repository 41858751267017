import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom";

import Home from "./pages/Home";
import QRgen from "./pages/QRgenerator";
import QRscan from "./pages/QRscanner";
import { ToolProvider } from "./contexts/tool";
import { Inventory } from "./pages/Inventory";




{
  /* Still needs to be optimized for mobile. This can be done with CSS */
}

const App = () => {
  return (
    <div className="App">
      <div className="App-header">
        <ToolProvider>
          <Router>
            <div>
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/qr_generator">
                  <QRgen />
                </Route>
                <Route path="/qr_scanner">
                  <QRscan />
                </Route>
                <Route path="/inventory">
                  <Inventory />
                </Route>
              </Switch>
            </div>
          </Router>
        </ToolProvider>
      </div>
    </div>
  );
};

export default App;
